var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.searchResults, function (searchResult) {
      return _c(
        "SearchResult",
        _vm._b(
          { key: searchResult.id, on: { resultClicked: _vm.resultClicked } },
          "SearchResult",
          searchResult,
          false
        )
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
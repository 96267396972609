<template>
  <div class="searchResultContainer" @click="emitResultClicked">
    <div class="thumb-container">
      <div class="thumbnail" :style="{ backgroundImage: 'url('+ image +')'}"></div>
    </div>
    <div class="details">
      <div class="subject">{{subject}}</div>
      <div class="divider"></div>
      <div class="body" v-if="body">{{ body | striphtml | truncate }}</div>
      <div class="no-body" v-if="dealership || position">
        <div class="position" v-if="position">{{position}}</div>
        <div class="dealership" v-if="dealership">{{dealership}}</div>
      </div>
      <div class="by-line" v-if="byLine">By {{byLine}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchResult',
  props: {
    id: {
      type: String,
      required: true,
    },
    subject: {
      type: String,
      required: true,
    },
    body: {
      type: String,
    },
    dealership: {
      type: String,
    },
    position: {
      type: String,
    },
    byLine: {
      type: String,
    },
    thumbnailAssetId: {
      type: String,
    },
    rank: {
      type: Number,
    },
  },
  data() {
    return {
      image: '',
    };
  },
  methods: {
    emitResultClicked() {
      this.$emit('resultClicked', this.id);
    },
  },
  filters: {
    striphtml(value) {
      const div = document.createElement('div');
      div.innerHTML = value;
      const text = div.textContent || div.innerText || '';
      return text;
    },
    truncate(value) {
      let newValue = value;
      if (value.length > 375) {
        newValue = `${value.substring(0, 375)}...`;
      }
      return newValue;
    },
  },
  async created() {
    const asset = await this.$store.dispatch(
      'assetStore/downloadAsset',
      this.thumbnailAssetId,
    );
    if (asset) {
      this.image = window.URL.createObjectURL(new Blob([asset.data]));
    }
  },
};
</script>

<style lang="scss" scoped>
.searchResultContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0.5em;
  margin-bottom: 30px;
  transition: all 0.2s ease;

  &:hover {
    cursor: pointer;
    background: #eee;
  }

  .thumb-container {
    display: none;

    @include respond(sm) {
      display: block;
      flex: 1;
      margin-right: 35px;
    }

    .thumbnail {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: top;
      background-color: $LincolnGrayLighter;
      height: 150px;
      width: 125px;
    }
  }

  .details {
    @include respond(sm) {
      flex: 8;
    }

    .subject {
      font-weight: bold;
      color: $LincolnGrayDark;
      transition: all 0.2s ease;

      &:hover {
        color: $LincolnOrange;
      }
    }

    .divider {
      height: 1px;
      background: $LincolnOrange;
      width: 75px;
      margin-top: 20px;
    }

    .body,
    .no-body {
      color: $LincolnGray;
      margin: 20px 0;
    }

    .no-body {
      .position {
        text-transform: uppercase;
      }
    }

    .by-line {
      color: rgb(152, 152, 152);
      font-size: 0.8em;
    }
  }
}
</style>

<template>
  <div>
    <SearchResult v-for="searchResult in searchResults"
                  v-bind="searchResult"
                  v-bind:key="searchResult.id"
                  @resultClicked="resultClicked" />
  </div>
</template>

<script>
import ResourceHandlers from '../../mixins/resourceHandlers.vue';
import SearchResult from './SearchResult.vue';

export default {
  name: 'SearchResultList',
  components: {
    SearchResult,
  },
  mixins: [
    ResourceHandlers,
  ],
  computed: {
    searchResults() {
      return this.$store.getters['searchStore/searchResults'];
    },
  },
  methods: {
    async resultClicked(id) {
      const result = this.searchResults.find(sr => sr.id === id);

      switch (result.type) {
        case 1: // brand story
          this.$router.push({ name: 'brandStory', query: { id: result.id } });
          break;
        case 2: // whpc
          this.$router.push({ name: 'stories', query: { id: result.id } });
          break;
        case 3: // resource doc
          await this.$downloader.downloadDocument(result.id);
          break;
        case 4: // resource video
          this.showVideoModal(result.id);
          break;
        case 5: // user
          this.$router.push({ name: 'profile', query: { id: result.id } });
          break;
        default:
          break;
      }
    },
  },
};
</script>

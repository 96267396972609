<template>
  <div class="content-wrapper">
    <div class="pgTitle">Search Results</div>
    <div class="search">
      <SearchResultList />
    </div>
  </div>
</template>

<script>
import SearchResultList from '../components/search/SearchResultList.vue';

export default {
  name: 'Search',
  components: {
    SearchResultList,
  },
  async created() {
    this.$analytics.trackPageView();
  },
};
</script>

<style lang="scss" scoped>
.pgTitle {
  text-align: center;
  @include font-size(2.875rem);
  margin: 10px 0 20px 0;
}

.search {
  max-width: 1100px;
  margin: 0 auto;
}
</style>
